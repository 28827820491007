import { isLoggedIn } from '@commonstock/common/src/auth'
import GlobalFeed from 'src/scopes/feed/GlobalFeed'
import createLayout from 'src/scopes/layout/createLayout'
import { Screen } from 'src/scopes/analytics/constants'
import { GetServerSideProps } from 'next'
import serverConfig from 'src/config/config.server'
import getPublicResource from 'src/utils/getPublicResource'
import config from 'src/config'
import { GlobalFeedOutput, GlobalFeedPeriodType, GLOBAL_FEED_PATH } from '@commonstock/common/src/api/feed'

type Props = {
  publicTopFeed?: GlobalFeedOutput
  publicNewFeed?: GlobalFeedOutput
  origin?: string
  period?: GlobalFeedPeriodType
}
export default function FeedScreen({ publicNewFeed, publicTopFeed, period }: Props) {
  return <GlobalFeed publicNewFeed={publicNewFeed} publicTopFeed={publicTopFeed} ssrPeriod={period} />
}

FeedScreen.getLayout = createLayout({ publicScreen: true, hasSingleColumn: () => isLoggedIn() })
FeedScreen.screen = Screen.Home

export const getServerSideProps: GetServerSideProps = async ({ query, req, res }) => {
  // @TODO is there a more reliable way to get the scheme / origin?
  res?.setHeader('Cache-Control', serverConfig.cacheControl)
  const origin = (process.env.NODE_ENV !== 'production' ? 'http://' : 'https://') + req?.headers.host

  const headers = new Headers()
  headers.append('Authorization', serverConfig.serviceToken || '')

  function getFeed(query: string) {
    return getPublicResource<GlobalFeedOutput>(config.apiPaths.Gateway + GLOBAL_FEED_PATH + query, { headers })
  }

  const { period = GlobalFeedPeriodType.RECENT } = query
  const [publicTopFeed, publicNewFeed] = await Promise.all([
    getFeed(`?sort=Top&limit=20&period=${period}`),
    getFeed(`?sort=New&limit=20`)
  ])

  const props: Props = { origin, period: period.toString() as GlobalFeedPeriodType }
  if (publicTopFeed?.success) props.publicTopFeed = publicTopFeed.payload
  if (publicNewFeed.success) props.publicNewFeed = publicNewFeed.payload

  return { props }
}
